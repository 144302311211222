require.config({
    paths: {
        jquery: '../../bower_components/jquery/dist/jquery',
        window: 'browser-modules',
        document: 'browser-modules',
        $window: 'browser-modules',
        $document: 'browser-modules'
    },
    shim: {}
});

define("require-config", function(){});

